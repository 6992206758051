import { Modal, ScrollArea, Title, Text, Stack } from "@mantine/core"
import { useDisclosure, useLocalStorage } from "@mantine/hooks"

import { ConsentAskStatus } from "@ensol/shared/entities/houses/switchgrid"

import { Field } from "@ensol/entool/components/form/Field"
import { DISPLAY_SWITCHGRID_SECTION_SETTING_KEY } from "@ensol/entool/pages/Installation/SimulationParameters/Settings"
import { useAutoRefreshWhilePending } from "@ensol/entool/pages/Installation/SimulationParameters/Switchgrid/useAutoRefreshWhilePending"
import { useGetHouseQuery } from "@ensol/entool/queries/houses"

import { ConsentForm } from "./ConsentForm"
import { CreateConsentAsk } from "./CreateConsentAsk"
import { CreateOrder } from "./CreateOrder"

type Props = {
  houseId: string
}

export const SwitchgridParameters = ({ houseId }: Props) => {
  const { data: house } = useGetHouseQuery(houseId)
  useAutoRefreshWhilePending(house)
  const [
    isConsentModalOpen,
    { open: openConsentModal, close: closeConsentModal },
  ] = useDisclosure(false)
  const [isSwitchgridSectionDisplayed] = useLocalStorage({
    key: DISPLAY_SWITCHGRID_SECTION_SETTING_KEY,
    defaultValue: false,
  })

  if (!house || !isSwitchgridSectionDisplayed) {
    return null
  }

  const { switchgridConsent, switchgridOrder } = house

  return (
    <>
      <Field
        name="Récupération des données Enedis"
        subtitle="Utiliser les données de consommation permet d'améliorer la simulation"
      >
        <CreateConsentAsk
          switchgridConsent={switchgridConsent}
          openConsentModal={openConsentModal}
        />
        {switchgridConsent &&
          switchgridConsent.askStatus === ConsentAskStatus.ACCEPTED &&
          switchgridConsent.expirationDate >= new Date() && (
            <CreateOrder houseId={houseId} switchgridOrder={switchgridOrder} />
          )}
      </Field>
      <Modal
        size={1000}
        radius="lg"
        padding="xl"
        opened={isConsentModalOpen}
        scrollAreaComponent={ScrollArea.Autosize}
        onClose={closeConsentModal}
        title={
          <Stack gap="4">
            <Title order={2}>Demande de consentement</Title>
            <Text size="sm" c="gray.6">
              Vérifiez / complétez les informations et envoyez la demande de
              consentement pour obtenir un lien de signature à partager au
              client
            </Text>
          </Stack>
        }
      >
        <ConsentForm house={house} closeConsentModal={closeConsentModal} />
      </Modal>
    </>
  )
}
