import { Button, Stack, Table, Text } from "@mantine/core"
import { IconPlus } from "@tabler/icons-react"

import { ProspectResponses } from "@ensol/types/endpoints/prospects"

import { InstallationRow } from "./InstallationRow"
import { useInstallations } from "./useInstallations"

export type InstallationsProps = {
  houseId: string
  address: string
  installations: ProspectResponses.ProspectDetails["houses"][number]["installations"]
}

export const Installations = ({
  houseId,
  address,
  installations,
}: InstallationsProps) => {
  const { createNewSimulation, goToSimulation, canBundle } = useInstallations({
    houseId,
    address,
    installations,
  })

  return (
    <Stack flex="1" justify="space-between">
      {installations.length > 0 ? (
        <Table highlightOnHover>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Nom installation</Table.Th>
              <Table.Th>Produit(s)</Table.Th>
              <Table.Th>Statut devis</Table.Th>
              <Table.Th>Créée le</Table.Th>
              <Table.Th>Bundle</Table.Th>
              <Table.Th />
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {installations.map((installation) => (
              <InstallationRow
                key={installation.id}
                installation={installation}
                onLineClick={() => goToSimulation(installation.id)}
                canBundle={canBundle}
              />
            ))}
          </Table.Tbody>
        </Table>
      ) : (
        <Text c="gray">
          Cette maison n&apos;a pas encore d&apos;installation...
        </Text>
      )}
      <Button
        leftSection={<IconPlus />}
        onClick={createNewSimulation}
        w="220"
        variant="light"
        data-test="createInstallationButton"
      >
        Nouvelle installation
      </Button>
    </Stack>
  )
}
