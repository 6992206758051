import { Stack, Select, Button, Group, Chip, ActionIcon } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { IconCurrencyEuro, IconPlus, IconTrash } from "@tabler/icons-react"

import { extraWorksSchema } from "@ensol/types/forms/installations/extraWorks"
import { ExtraWorks } from "@ensol/types/installation"

import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { EXTRA_WORKS_OPTIONS } from "@ensol/entool/utils/installations/extraWorks"

type Props = {
  value?: ExtraWorks
  onChange: (value: ExtraWorks) => void
}

export const ExtraWorksSelect = ({ value, onChange }: Props) => {
  const form = useForm({
    onValuesChange: (values) => {
      form.validate()
      onChange(values.extraWorks)
    },
    validateInputOnChange: true,
    validate: zodResolver(extraWorksSchema),
    initialValues: {
      extraWorks: value ?? [],
    },
  })

  return (
    <Stack align="flex-end">
      {form.values.extraWorks.map((extraWork, index) => {
        return (
          <Group
            key={`extraWork_${extraWork.type}_${index}`}
            align="flex-start"
          >
            <Select
              name="Type de travaux"
              w={FIELD_WIDTH}
              data={EXTRA_WORKS_OPTIONS}
              searchable
              data-test="extraWorkTypeSelect"
              {...form.getInputProps(`extraWorks.${index}.type`)}
            />
            <Stack w="80" align="center">
              <Chip
                variant="light"
                icon={<IconCurrencyEuro size={16} />}
                checked={extraWork.isBilled}
                data-test="extraWorkIsBilled"
                {...form.getInputProps(`extraWorks.${index}.isBilled`)}
              >
                {extraWork.isBilled === true ? "Facturé" : "Offert"}
              </Chip>
            </Stack>
            <ActionIcon
              aria-label="Supprimer"
              variant="transparent"
              p="4"
              color="red"
              onClick={() => form.removeListItem("extraWorks", index)}
            >
              <IconTrash size={16} color="red" />
            </ActionIcon>
          </Group>
        )
      })}
      <Button
        size="sm"
        variant="transparent"
        p="0"
        leftSection={<IconPlus size={18} />}
        data-test="addExtraWorksButton"
        onClick={() =>
          form.insertListItem("extraWorks", { type: null, isBilled: true })
        }
      >
        Ajouter des travaux
      </Button>
    </Stack>
  )
}
