import "dayjs/locale/fr"

import dayjs from "dayjs"
import LocalizedFormat from "dayjs/plugin/localizedFormat"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { EmailInput } from "@ensol/types/forms/projects/emails"
import { Nullable } from "@ensol/types/utils"

import { hasPhotovoltaicInstallation } from "@ensol/shared/entities/installations/characteristics"
import { GENDERS } from "@ensol/shared/entities/prospects"
import { getApplication } from "@ensol/shared/material/photovoltaic/applications"
import { getInverter } from "@ensol/shared/material/photovoltaic/inverters"
import { getPanel } from "@ensol/shared/material/photovoltaic/panels"
import { EnsolError } from "@ensol/shared/utils/errors"
import { getFileUrl } from "@ensol/shared/utils/files"
import { formatDate, WEEK_FORMAT } from "@ensol/shared/utils/format"

dayjs.extend(LocalizedFormat)
dayjs.locale("fr")

export enum EmailEvent {
  ENEDIS_EDF_OA_SUBMITTED = "enedis-edf-oa-submitted",
  DEPOSIT_INVOICE_PAID = "deposit-invoice-paid",
  FINAL_INVOICE_PAID = "final-invoice-paid",
  PRELIMINARY_REQUEST_ACKNOWLEDGED = "preliminary-request-acknowledged",
  PRELIMINARY_REQUEST_ABF_ACKNOWLEDGED = "preliminary-request-abf-acknowledged",
  PRELIMINARY_REQUEST_VALIDATED = "preliminary-request-validated",
  TECHNICAL_VISIT_SCHEDULED = "technical-visit-scheduled",
  TECHNICAL_VISIT_COMPLETED = "technical-visit-completed",
  INSTALLATION_SCHEDULED = "installation-scheduled",
  INSTALLATION_DONE = "installation-done",
  CONSUEL_SUBMITTED = "consuel-submitted",
  CONSUEL_FIRST_NOTICE_OF_VISIT = "consuel-first-notice-of-visit",
  CONSUEL_SECOND_NOTICE_OF_VISIT = "consuel-second-notice-of-visit",
  CONSUEL_VALIDATED = "consuel-validated",
  ENEDIS_ACTIVATED = "enedis-activated",
  INSTALLATION_CERTIFICATE_SENT = "installation-certificate-sent",
  EDF_OA_CERTIFICATE_SENT = "edf-oa-certificate-sent",
}

export const getProjectEmailData = (
  eventName: EmailEvent,
  project: ProjectResponses.Project,
  apiBaseUrl: string,
): Nullable<EmailInput> & { email: string } => {
  const { house } = project.installation
  const { prospect } = house
  const { operationsOwner } = project

  if (!hasPhotovoltaicInstallation(project.installation)) {
    throw new EnsolError(
      "Les emails automatiques ne sont pas disponibles pour les projets sans installation photovoltaïque",
    )
  }

  const { photovoltaicInstallation } = project.installation

  const panel = getPanel(photovoltaicInstallation.panelType)
  const inverter = getInverter(photovoltaicInstallation.inverterType)
  const application = getApplication(inverter.brand)

  const commonData = {
    email: prospect.email,
    formalName:
      prospect.gender && prospect.lastName
        ? `${GENDERS[prospect.gender].long} ${prospect.lastName}`
        : null,
    opsOwnerPhone: operationsOwner?.phone ?? null,
    opsOwnerName:
      operationsOwner && operationsOwner.firstName && operationsOwner.lastName
        ? `${operationsOwner.firstName} ${operationsOwner.lastName}`
        : null,
  }

  switch (eventName) {
    case EmailEvent.ENEDIS_EDF_OA_SUBMITTED:
      return { eventName: EmailEvent.ENEDIS_EDF_OA_SUBMITTED, ...commonData }
    case EmailEvent.DEPOSIT_INVOICE_PAID:
      return { eventName: EmailEvent.DEPOSIT_INVOICE_PAID, ...commonData }
    case EmailEvent.FINAL_INVOICE_PAID:
      return { eventName: EmailEvent.FINAL_INVOICE_PAID, ...commonData }
    case EmailEvent.PRELIMINARY_REQUEST_ACKNOWLEDGED:
      return {
        eventName: EmailEvent.PRELIMINARY_REQUEST_ACKNOWLEDGED,
        ...commonData,
        acknowledgmentLink:
          project.preliminaryRequestAcknowledgmentPath !== null
            ? getFileUrl(
                apiBaseUrl,
                project.preliminaryRequestAcknowledgmentPath,
              )
            : null,
      }
    case EmailEvent.PRELIMINARY_REQUEST_ABF_ACKNOWLEDGED:
      return {
        eventName: EmailEvent.PRELIMINARY_REQUEST_ABF_ACKNOWLEDGED,
        ...commonData,
        acknowledgmentABFLink:
          project.preliminaryRequestABFAcknowledgmentPath !== null
            ? getFileUrl(
                apiBaseUrl,
                project.preliminaryRequestABFAcknowledgmentPath,
              )
            : null,
      }
    case EmailEvent.PRELIMINARY_REQUEST_VALIDATED:
      return {
        eventName: EmailEvent.PRELIMINARY_REQUEST_VALIDATED,
        ...commonData,
        approvalLink:
          project.preliminaryRequestApprovalPaths.length > 0
            ? getFileUrl(apiBaseUrl, project.preliminaryRequestApprovalPaths[0])
            : null,
      }
    case EmailEvent.TECHNICAL_VISIT_SCHEDULED:
      return {
        eventName: EmailEvent.TECHNICAL_VISIT_SCHEDULED,
        ...commonData,
        date: formatDate(
          project.technicalVisitStartDate,
          "dddd DD/MM [à] HH:mm",
        ),
      }
    case EmailEvent.TECHNICAL_VISIT_COMPLETED:
      return {
        eventName: EmailEvent.TECHNICAL_VISIT_COMPLETED,
        ...commonData,
      }
    case EmailEvent.INSTALLATION_SCHEDULED: {
      const installationDurationInDays =
        project.installationStartDate !== null &&
        project.installationEndDate !== null
          ? dayjs(project.installationEndDate).diff(
              dayjs(project.installationStartDate),
              "day",
            ) + 1
          : null

      return {
        eventName: EmailEvent.INSTALLATION_SCHEDULED,
        ...commonData,
        applicationName: application.name,
        applicationLink: application.url,
        installationDates:
          installationDurationInDays !== null
            ? installationDurationInDays > 1
              ? `entre le ${formatDate(project.installationStartDate, "dddd DD/MM")} et le ${formatDate(project.installationEndDate, "dddd DD/MM")}`
              : `le ${formatDate(project.installationStartDate, "dddd DD/MM")}`
            : null,
        installationDurationInDays,
      }
    }
    case EmailEvent.INSTALLATION_DONE:
      return {
        eventName: EmailEvent.INSTALLATION_DONE,
        ...commonData,
        panelNoticeLink: panel.techDocUrl,
        inverterNoticeLink: inverter.techDocUrl,
        applicationName: application.name,
        applicationLink: application.url,
      }
    case EmailEvent.CONSUEL_SUBMITTED:
      return { eventName: EmailEvent.CONSUEL_SUBMITTED, ...commonData }
    case EmailEvent.CONSUEL_FIRST_NOTICE_OF_VISIT:
      return {
        eventName: EmailEvent.CONSUEL_FIRST_NOTICE_OF_VISIT,
        ...commonData,
        consuelFirstNoticeOfVisitLink:
          project.consuelFirstNoticeOfVisitPath !== null
            ? getFileUrl(apiBaseUrl, project.consuelFirstNoticeOfVisitPath)
            : null,
        consuelVisitWeek: project.consuelSummonsDate
          ? formatDate(project.consuelSummonsDate, WEEK_FORMAT)
          : null,
      }
    case EmailEvent.CONSUEL_SECOND_NOTICE_OF_VISIT:
      return {
        eventName: EmailEvent.CONSUEL_SECOND_NOTICE_OF_VISIT,
        ...commonData,
        consuelSecondNoticeOfVisitLink:
          project.consuelSecondNoticeOfVisitPath !== null
            ? getFileUrl(apiBaseUrl, project.consuelSecondNoticeOfVisitPath)
            : null,
        consuelVisitDateTime: formatDate(
          project.consuelSummonsDate,
          "DD/MM/YYYY [à] HH:mm",
        ),
      }
    case EmailEvent.CONSUEL_VALIDATED:
      return {
        eventName: EmailEvent.CONSUEL_VALIDATED,
        ...commonData,
        consuelAttestationLink:
          project.consuelCertificatePath !== null
            ? getFileUrl(apiBaseUrl, project.consuelCertificatePath)
            : null,
      }
    case EmailEvent.ENEDIS_ACTIVATED:
      return {
        eventName: EmailEvent.ENEDIS_ACTIVATED,
        ...commonData,
        enedisActivationDate: formatDate(project.enedisActivationDate),
      }
    case EmailEvent.INSTALLATION_CERTIFICATE_SENT:
      return {
        eventName: EmailEvent.INSTALLATION_CERTIFICATE_SENT,
        ...commonData,
        signatureLink: project.installationCertificateSignatureLink,
      }
    case EmailEvent.EDF_OA_CERTIFICATE_SENT:
      return {
        eventName: EmailEvent.EDF_OA_CERTIFICATE_SENT,
        ...commonData,
        certificateLink:
          project.edfOaCertificatePath !== null
            ? getFileUrl(apiBaseUrl, project.edfOaCertificatePath)
            : null,
      }
    default:
      throw new Error("Invalid event name")
  }
}
