import {
  PreliminaryRequestStep as PrismaPreliminaryRequestStep,
  TechnicalVisitStep as PrismaTechnicalVisitStep,
  InstallationStep as PrismaInstallationStep,
  PaymentStep as PrismaPaymentStep,
  HardwareStep as PrismaHardwareStep,
  GreendealStep as PrismaGreendealStep,
  EnedisStep as PrismaEnedisStep,
  ConsuelStep as PrismaConsuelStep,
  EdfOaStep as PrismaEdfOaStep,
  FollowUpStep as PrismaFollowUpStep,
} from "@ensol/types/prisma-client"

export enum PreliminaryRequestStep {
  WAITING_FOR_INFO = "WAITING_FOR_INFO",
  PRELIMINARY_VALIDATION = "PRELIMINARY_VALIDATION",
  SUBMISSION_ON_HOLD = "SUBMISSION_ON_HOLD",
  SUBMISSION_PENDING = "SUBMISSION_PENDING",
  WAITING_FOR_SOLTEO = "WAITING_FOR_SOLTEO",
  SUBMITTED = "SUBMITTED",
  ACKNOWLEDGMENT = "ACKNOWLEDGMENT",
  INCOMPLETE = "INCOMPLETE",
  INCOMPLETE_WAITING_FOR_STAKEHOLDER = "INCOMPLETE_WAITING_FOR_STAKEHOLDER",
  INCOMPLETE_SUBMITTED = "INCOMPLETE_SUBMITTED",
  APPROVED = "APPROVED",
  AUTHORISATION_RECEIVED = "AUTHORISATION_RECEIVED",
  REJECTED = "REJECTED",
}

export enum TechnicalVisitStep {
  PENDING = "PENDING",
  SCHEDULING_ON_HOLD = "SCHEDULING_ON_HOLD",
  CANNOT_SCHEDULE = "CANNOT_SCHEDULE",
  REQUESTED = "REQUESTED",
  INVITATION_REJECTED = "INVITATION_REJECTED",
  SCHEDULED = "SCHEDULED",
  COMPLETED = "COMPLETED",
  BEING_PROCESSED = "BEING_PROCESSED",
  UPDATE_INSTALLATION = "UPDATE_INSTALLATION",
  WAITING_FOR_SALES_CHANGES = "WAITING_FOR_SALES_CHANGES",
  WAITING_FOR_SIGNATURE = "WAITING_FOR_SIGNATURE",
  VALIDATED_WITH_WORKS = "VALIDATED_WITH_WORKS",
  ON_HOLD = "ON_HOLD",
  REJECTED = "REJECTED",
  VALIDATED = "VALIDATED",
}

export enum GreendealStep {
  EMAIL_PENDING = "EMAIL_PENDING",
  SUBMISSION_PENDING = "SUBMISSION_PENDING",
  SUBMITTED = "SUBMITTED",
  DONE_BY_CLIENT = "DONE_BY_CLIENT",
  FILE_COMPLETED = "FILE_COMPLETED",
  VALIDATED = "VALIDATED",
}

export enum InstallationStep {
  PENDING = "PENDING",
  SCHEDULING_ON_HOLD = "SCHEDULING_ON_HOLD",
  CANNOT_SCHEDULE = "CANNOT_SCHEDULE",
  REQUESTED = "REQUESTED",
  SCHEDULED = "SCHEDULED",
  PROJECT_PREPARED = "PROJECT_PREPARED",
  IN_PROGRESS = "IN_PROGRESS",
  NEW_VISIT_NEEDED = "NEW_VISIT_NEEDED",
  COMPLETED = "COMPLETED",
  UPDATE_INSTALLATION = "UPDATE_INSTALLATION",
  CERTIFICATE_SENT = "CERTIFICATE_SENT",
  CERTIFICATE_SIGNED = "CERTIFICATE_SIGNED",
}

export enum PaymentStep {
  DEPOSIT_INVOICE_WAITING = "DEPOSIT_INVOICE_WAITING",
  DEPOSIT_INVOICE_TO_SEND = "DEPOSIT_INVOICE_TO_SEND",
  WAITING_FOR_DEPOSIT_PAYMENT = "WAITING_FOR_DEPOSIT_PAYMENT",
  DEPOSIT_INVOICE_PAID = "DEPOSIT_INVOICE_PAID",
  FINAL_INVOICE_TO_SEND = "FINAL_INVOICE_TO_SEND",
  WAITING_FOR_FINAL_PAYMENT = "WAITING_FOR_FINAL_PAYMENT",
  FINAL_INVOICE_PAID = "FINAL_INVOICE_PAID",
}

export enum HardwareStep {
  PREORDER_PENDING = "PREORDER_PENDING",
  PREORDER_DONE = "PREORDER_DONE",
  ORDER_PENDING = "ORDER_PENDING",
  PREPARATION_PENDING = "PREPARATION_PENDING",
  DELIVERY_PENDING = "DELIVERY_PENDING",
  ORDER_DELIVERED = "ORDER_DELIVERED",
  ORDER_CHECKED = "ORDER_CHECKED",
  ORDER_INSTALLED = "ORDER_INSTALLED",
}

export enum EnedisStep {
  PENDING = "PENDING",
  SUBMITTED = "SUBMITTED",
  VALIDATED = "VALIDATED",
  INCOMPLETE = "INCOMPLETE",
  ACTIVATION_PENDING = "ACTIVATION_PENDING",
  ACTIVATED = "ACTIVATED",
}

export enum ConsuelStep {
  PENDING = "PENDING",
  WAITING_FOR_SOLTEO = "WAITING_FOR_SOLTEO",
  SUBMITTED = "SUBMITTED",
  VISIT_NEEDED = "VISIT_NEEDED",
  VISIT_SCHEDULED = "VISIT_SCHEDULED",
  WORK_NEEDED = "WORK_NEEDED",
  WORK_SCHEDULED = "WORK_SCHEDULED",
  WORK_DONE = "WORK_DONE",
  COMPLIANCE_CERTIFICATE_SIGNED = "COMPLIANCE_CERTIFICATE_SIGNED",
  VALIDATED = "VALIDATED",
}

export enum EdfOaStep {
  EMAIL_SENT = "EMAIL_SENT",
  CERTIFICATE_TO_SEND = "CERTIFICATE_TO_SEND",
  CERTIFICATE_SENT = "CERTIFICATE_SENT",
}

export enum FollowUpStep {
  PENDING = "PENDING",
  WAITING_FOR_CONSENT = "WAITING_FOR_CONSENT",
  CONSENT_REFUSED = "CONSENT_REFUSED",
  J7_TO_SEND = "J7_TO_SEND",
  M1_TO_SEND = "M1_TO_SEND",
  M1_SENT = "M1_SENT",
  M1_IGNORED = "M1_IGNORED",
  ACTIONS_REQUIRED = "ACTIONS_REQUIRED",
  DONE = "DONE",
}

const OPS_PROCESSES = {
  preliminaryRequestStep: PreliminaryRequestStep,
  technicalVisitStep: TechnicalVisitStep,
  installationStep: InstallationStep,
  paymentStep: PaymentStep,
  hardwareStep: HardwareStep,
  greendealStep: GreendealStep,
  enedisStep: EnedisStep,
  consuelStep: ConsuelStep,
  edfOaStep: EdfOaStep,
}

export type OpsProcessId = keyof typeof OPS_PROCESSES

export const PROCESSES = {
  ...OPS_PROCESSES,
  followUpStep: FollowUpStep,
}

export type ProcessId = keyof typeof PROCESSES

// Object.keys return type is string[], so we need to cast it
export const PROJECT_MILESTONE_FIELDS = [
  "status",
  ...Object.keys(PROCESSES),
] as Array<keyof typeof PROCESSES | "status">

export const getStepIndex = (step: ProcessStep | null, processId: ProcessId) =>
  Object.values(PROCESSES[processId]).findIndex(
    (processStep) => processStep === step,
  )

export type ProcessStep =
  | PrismaPreliminaryRequestStep
  | PrismaTechnicalVisitStep
  | PrismaInstallationStep
  | PrismaPaymentStep
  | PrismaHardwareStep
  | PrismaGreendealStep
  | PrismaEnedisStep
  | PrismaConsuelStep
  | PrismaEdfOaStep
  | PrismaFollowUpStep
