import { showNotification } from "@mantine/notifications"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useCallback } from "react"
import { useParams } from "react-router-dom"

import { SwitchgridResponses } from "@ensol/types/endpoints/switchgrid"
import { DataConsentInput } from "@ensol/types/forms/houses/dataConsent"

import { httpClient } from "@ensol/entool/backend/axios"
import { queryClient } from "@ensol/entool/backend/queryClient"

const useInvalidateInstallationQuery = () => {
  const { installationId } = useParams()

  return useCallback(async () => {
    if (installationId) {
      await queryClient.invalidateQueries({
        queryKey: ["installations", installationId],
      })
    }
  }, [installationId])
}

export const useSearchContracts = (name: string | null, address: string) => {
  const { data, isPending } = useQuery<SwitchgridResponses.Contracts>({
    queryKey: ["switchgrid", "search-contract", name, address],
    queryFn: async () => {
      if (name === null) {
        return { contracts: [] }
      }

      const response = await httpClient.get("/switchgrid/search-contract", {
        params: { name, address },
      })
      return response.data
    },
  })

  return {
    matchingContracts: data?.contracts ?? [],
    isSearchingContracts: isPending,
  }
}

export const useAskConsentMutation = (houseId: string) => {
  const invalidateInstallationQuery = useInvalidateInstallationQuery()

  return useMutation<void, string, DataConsentInput>({
    mutationFn: async (dataConsentInput) => {
      const response = await httpClient.post(
        `/switchgrid/${houseId}/ask`,
        dataConsentInput,
      )
      return response.data
    },
    onSuccess: async () => {
      showNotification({ message: "Demande de consentement envoyée !" })
      await invalidateInstallationQuery()
      await queryClient.invalidateQueries({ queryKey: ["houses", houseId] })
    },
  })
}

export const useCreateOrderMutation = (houseId: string) => {
  const invalidateInstallationQuery = useInvalidateInstallationQuery()

  return useMutation({
    mutationFn: async () => {
      const response = await httpClient.post(`/switchgrid/${houseId}/order`)
      return response.data
    },
    onSuccess: async () => {
      showNotification({ message: "Demande de données envoyée !" })
      await invalidateInstallationQuery()
      await queryClient.invalidateQueries({ queryKey: ["houses", houseId] })
    },
  })
}
