import {
  Badge,
  Group,
  NumberInput,
  Stack,
  Tabs,
  TitleOrder,
  Text,
  Divider,
  Button,
} from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { IconLayout2, IconSolarPanel } from "@tabler/icons-react"
import _ from "lodash"
import { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { photovoltaicInstallationSchema } from "@ensol/types/forms/installations/photovoltaic"
import { ProductionDataSource } from "@ensol/types/simulation"

import { formatRoofSectionName } from "@ensol/shared/entities/houses/roofSection"
import { DEFAULT_PHOTOVOLTAIC_INSTALLATION } from "@ensol/shared/entities/installations/defaults"
import {
  computePanelsCount,
  getRecommendedPanelsCount,
} from "@ensol/shared/entities/installations/energy"
import { PHOTOVOLTAIC_SUBSIDIES } from "@ensol/shared/entities/installations/subsidies/photovoltaic"
import { formatCurrency } from "@ensol/shared/utils/format"

import { Field } from "@ensol/entool/components/form/Field"
import { ExtraWorksSelect } from "@ensol/entool/components/form/Installation/ExtraWorksSelect"
import { ProductWrapper } from "@ensol/entool/components/form/Installation/ProductWrapper"
import { NumberInputWithSuggestion } from "@ensol/entool/components/form/NumberInputWithSuggestion"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { findSelectedOption } from "@ensol/entool/utils/form/radio"
import { isFormValid } from "@ensol/entool/utils/form/validation"
import { computeHouseRoofSectionsWithPanels } from "@ensol/entool/utils/house/roofSections"
import { usePhotovoltaicMaterialOptions } from "@ensol/entool/utils/installations/photovoltaic"

type Props = {
  isNewInstallation: boolean
  titleOrder?: TitleOrder
  photovoltaicInstallation: InstallationsResponses.PhotovoltaicInstallation | null
  onChange: (
    data: InstallationsResponses.PhotovoltaicInstallation | null,
  ) => void
  setError: (error: string) => void
  clearError: () => void
  house: Pick<
    HousesResponses.House<{ include: { roofSections: true } }>,
    | "currentType"
    | "hasFlatRoof"
    | "hasGroundInstallation"
    | "roofSections"
    | "postcode"
    | "constructionYear"
    | "roofType"
  >
  productionDataSource?: ProductionDataSource
  estimatedProduction?: number
  yearlyConsumption?: number
  maxPanelsCount?: number
  totalDiscount: number
}

export const PhotovoltaicInstallation = ({
  isNewInstallation,
  titleOrder,
  photovoltaicInstallation,
  onChange,
  ...props
}: Props) => (
  <ProductWrapper
    type="Photovoltaic"
    installationHasProduct={photovoltaicInstallation !== null}
    addProduct={() =>
      onChange({
        ...DEFAULT_PHOTOVOLTAIC_INSTALLATION,
        panelsCount: 0,
        roofSectionsWithPanels: [],
      })
    }
    deleteProduct={() => onChange(null)}
    title="Photovoltaïque"
    titleOrder={titleOrder}
    Icon={IconSolarPanel}
    Actions={
      !isNewInstallation && (
        <Button
          component={Link}
          to="panels-layout"
          w="200"
          leftSection={<IconLayout2 />}
          color="pink"
          variant="light"
        >
          Calepinage
        </Button>
      )
    }
    addButtonLabel="Ajouter des panneaux photovoltaïques"
  >
    {photovoltaicInstallation !== null && (
      <PhotovoltaicInstallationForm
        photovoltaicInstallation={photovoltaicInstallation}
        onChange={onChange}
        {...props}
      />
    )}
  </ProductWrapper>
)

const PhotovoltaicInstallationForm = ({
  photovoltaicInstallation,
  onChange,
  setError,
  clearError,
  house,
  productionDataSource,
  estimatedProduction,
  yearlyConsumption,
  maxPanelsCount,
  totalDiscount,
}: Omit<Props, "isNewInstallation" | "titleOrder"> & {
  photovoltaicInstallation: InstallationsResponses.PhotovoltaicInstallation
}) => {
  const [panelsCountSuggestion, setPanelsCountSuggestion] = useState<number>()
  const productionConsumptionPercent =
    estimatedProduction && yearlyConsumption
      ? Math.round((estimatedProduction / yearlyConsumption) * 100)
      : null

  const form = useForm({
    onValuesChange: (values) => onChange(values),
    validate: zodResolver(photovoltaicInstallationSchema),
    initialValues: {
      ...photovoltaicInstallation,
      roofSectionsWithPanels: computeHouseRoofSectionsWithPanels(
        house.roofSections,
        photovoltaicInstallation.roofSectionsWithPanels,
      ),
    },
  })

  const {
    panelType,
    inverterType,
    optimizerType,
    panelsCount,
    optimizerCount,
    subsidyType,
    roofSectionsWithPanels,
  } = form.values

  // This is necessary in case the roof sections have been updated
  useEffect(() => {
    if (
      !_.isEqual(
        house.roofSections.map(({ id }) => id),
        roofSectionsWithPanels.map(({ roofSection }) => roofSection.id),
      )
    ) {
      form.setFieldValue(
        "roofSectionsWithPanels",
        computeHouseRoofSectionsWithPanels(
          house.roofSections,
          photovoltaicInstallation.roofSectionsWithPanels,
        ),
      )
    }
  }, [
    roofSectionsWithPanels,
    house.roofSections,
    form,
    photovoltaicInstallation.roofSectionsWithPanels,
  ])

  const {
    panelsOptions,
    invertersOptions,
    optimizerOptions,
    minOptimizerCount,
    maxOptimizerCount,
  } = usePhotovoltaicMaterialOptions({
    panelsCount,
    panelType,
    inverterType,
    currentType: house.currentType,
  })

  const subsidyOptions = useMemo(() => {
    return _.map(PHOTOVOLTAIC_SUBSIDIES, (subsidyInfo, subsidyType) => {
      if (!isFormValid(form)) {
        return { label: subsidyInfo.shortName, value: subsidyType }
      }

      const isEligible = subsidyInfo.isEligible({
        postcode: house.postcode,
        hasGroundInstallation: house.hasGroundInstallation,
      })

      const amount = subsidyInfo.computeAmount({
        photovoltaicInstallation: form.values,
        house,
        totalDiscount,
      })

      return {
        label: subsidyInfo.shortName,
        value: subsidyType,
        disabled: subsidyInfo.disabled || !isEligible,
        subtitle: subsidyInfo.disabled
          ? "Indisponible"
          : isEligible
            ? `Montant: ${formatCurrency(amount)}`
            : "Non éligible",
      }
    })
  }, [form, house, totalDiscount])

  useEffect(() => {
    if (house.hasGroundInstallation) {
      form.setFieldValue("subsidyType", null)
    }
  }, [form, subsidyType, house.hasGroundInstallation])

  // Suggestions for panels count
  useEffect(() => {
    if (yearlyConsumption) {
      const recommendedPanelsCount = getRecommendedPanelsCount({
        yearlyConsumption,
        panelType,
        shouldBeCapped: false,
      })

      setPanelsCountSuggestion(recommendedPanelsCount)
    }
  }, [panelType, yearlyConsumption])

  useEffect(() => {
    if (findSelectedOption(panelType, panelsOptions)?.incompatible) {
      form.setFieldError("panelType", "Type de panneau invalide")
    } else {
      form.clearFieldError("panelType")
    }

    if (findSelectedOption(inverterType, invertersOptions)?.incompatible) {
      form.setFieldError("inverterType", "Onduleur invalide")
    } else {
      form.clearFieldError("inverterType")
    }

    if (findSelectedOption(optimizerType, optimizerOptions)?.incompatible) {
      form.getInputProps("optimizerType").onChange(null)
    }
  }, [
    form,
    panelType,
    inverterType,
    optimizerType,
    panelsOptions,
    invertersOptions,
    optimizerOptions,
  ])

  useEffect(() => {
    form.setFieldValue(
      "panelsCount",
      computePanelsCount(roofSectionsWithPanels),
    )
  }, [form, roofSectionsWithPanels])

  // Validate the form and update error state of the main installation form
  useEffect(() => {
    if (!isFormValid(form)) {
      setError("photovoltaic installation is invalid")
    } else {
      clearError()
    }
  })

  return (
    <>
      <Field name="Type Panneaux">
        <RadioGroup
          options={panelsOptions}
          {...form.getInputProps("panelType")}
        />
      </Field>
      <Stack>
        <Group justify="space-between" mb={8}>
          {!!panelsCount && (
            <Group gap="4">
              <Text fw={500} c="gray.7">
                Total des panneaux :
              </Text>
              <Badge
                variant="light"
                color={
                  productionDataSource === ProductionDataSource.GOOGLE &&
                  maxPanelsCount &&
                  maxPanelsCount <= panelsCount
                    ? "red"
                    : "blue"
                }
              >
                {productionDataSource === ProductionDataSource.GOOGLE &&
                maxPanelsCount
                  ? `${panelsCount} / ${maxPanelsCount}`
                  : panelsCount}
              </Badge>
            </Group>
          )}
          {panelsCountSuggestion && (
            <Group gap="4">
              <Text fw={500} c="gray.7">
                Nombre de panneaux recommandé :
              </Text>
              <Badge variant="light" color="blue">
                {panelsCountSuggestion}
              </Badge>
            </Group>
          )}
        </Group>
        <Tabs
          orientation="vertical"
          defaultValue="roofSection_0"
          styles={{ list: { width: 200 }, tabLabel: { width: "100%" } }}
        >
          <Tabs.List>
            {roofSectionsWithPanels.map(({ roofSection }, index) => {
              return (
                <Tabs.Tab
                  key={`roofSection_${index}`}
                  value={`roofSection_${index}`}
                  title={
                    productionDataSource === ProductionDataSource.GOOGLE &&
                    index > 0
                      ? "Uniquement disponible avec PVGIS"
                      : undefined
                  }
                  disabled={
                    productionDataSource === ProductionDataSource.GOOGLE &&
                    index > 0
                  }
                >
                  <Group wrap="nowrap" justify="space-between">
                    <Text size="sm">
                      {formatRoofSectionName(
                        roofSection,
                        house.hasGroundInstallation,
                        house.hasFlatRoof,
                      )}
                    </Text>
                    <Badge variant="light" color="blue">
                      {roofSectionsWithPanels[index].panelsCount}
                    </Badge>
                  </Group>
                </Tabs.Tab>
              )
            })}
          </Tabs.List>
          {roofSectionsWithPanels.map((_, index) => {
            return (
              <Tabs.Panel
                key={`roofSection_${index}`}
                value={`roofSection_${index}`}
              >
                <Stack pl={24}>
                  <Field name="Nombre de Panneaux" noBorder>
                    <NumberInputWithSuggestion
                      w={200}
                      ml="auto"
                      disabled={
                        productionDataSource === ProductionDataSource.GOOGLE &&
                        index > 0
                      }
                      suggestion={
                        productionDataSource === ProductionDataSource.GOOGLE &&
                        index === 0
                          ? panelsCountSuggestion
                          : undefined
                      }
                      min={0}
                      max={
                        productionDataSource === ProductionDataSource.GOOGLE
                          ? maxPanelsCount
                          : 80
                      }
                      data-test="panelsCount"
                      {...form.getInputProps(
                        `roofSectionsWithPanels.${index}.panelsCount`,
                      )}
                    />
                    {productionConsumptionPercent !== null && (
                      <Group gap="4">
                        <Text fw={500} size="sm" c="gray.7">
                          Ratio prod / conso :
                        </Text>
                        <Badge
                          variant="light"
                          color={
                            productionConsumptionPercent < 80 ||
                            productionConsumptionPercent > 90
                              ? "orange"
                              : "green"
                          }
                          size="sm"
                        >
                          {productionConsumptionPercent}%
                        </Badge>
                      </Group>
                    )}
                  </Field>
                </Stack>
              </Tabs.Panel>
            )
          })}
        </Tabs>
        <Divider mt={12} mb={24} />
      </Stack>
      <Field name="Onduleurs">
        <RadioGroup
          options={invertersOptions}
          {...form.getInputProps("inverterType")}
        />
      </Field>
      <Stack gap="28">
        <Field name="Optimiseurs" noBorder={optimizerType !== null}>
          <RadioGroup
            nullable
            options={optimizerOptions}
            {...form.getInputProps("optimizerType")}
            onChange={(value) => {
              if (value !== null && optimizerCount === null) {
                form.getInputProps("optimizerCount").onChange(panelsCount)
              }
              form.getInputProps("optimizerType").onChange(value)
            }}
          />
        </Field>
        {optimizerType !== null && (
          <Field name="Nombre d'Optimiseurs">
            <NumberInput
              min={minOptimizerCount}
              max={maxOptimizerCount}
              {...form.getInputProps("optimizerCount")}
              value={optimizerCount ?? panelsCount}
            />
          </Field>
        )}
      </Stack>
      <Field name="Travaux supplémentaires">
        <ExtraWorksSelect {...form.getInputProps("extraWorks")} />
      </Field>
      <Field name="Subventions" noBorder>
        <RadioGroup
          options={subsidyOptions}
          nullable
          {...form.getInputProps("subsidyType")}
        />
      </Field>
    </>
  )
}
