import { Subsidy } from "@ensol/shared/entities/installations/subsidies"

export type FinancialGraphData = {
  year: number
  cost: number
}

export type MonthlyEnergySavings = {
  autoConsumedAmount: number
  resellAmount: number
  autoConsumptionSavingsPartPercent: number
  resellSavingsPartPercent: number
}

export type FinancialData = {
  billReductionRatio: number
  costPerInstalledCapacity: number
  panelsEnergyProductionCost: number
  oneYearSavings: number
  tenYearsSavings: number
  thirtyYearsSavings: number
  firstYearMonthlyEnergySavings: MonthlyEnergySavings
  graphs: {
    savingsPerYear: FinancialGraphData[]
    monthlyCostsWithoutPanels: FinancialGraphData[]
    monthlyCostsWithPanels: FinancialGraphData[]
  }
}

export type SimulationWarnings = {
  installedCapacity?: string
  totalDiscount?: string
}

export type SimulationResults = {
  subsidies: Subsidy[]
  installedCapacity: number
  autoConsumptionPercent: number
  yearlyConsumption: number
  estimatedYearlyProduction: number
  totalDiscount: number
  installationCost: number
  installationCostBeforeTax: number
  installationCostWithDiscount: number
  netInstallationCost: number
  monthsBeforeBreakEvenDate: number
  warnings: SimulationWarnings
} & FinancialData

export const ProductionDataSource = {
  PVGIS: "PVGIS",
  GOOGLE: "GOOGLE",
} as const

export type ProductionDataSource = keyof typeof ProductionDataSource

export const ConsumptionDataSource = {
  BILL: "BILL",
  SWITCHGRID: "SWITCHGRID",
} as const

export type ConsumptionDataSource = keyof typeof ConsumptionDataSource
