import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationsResponses } from "@ensol/types/endpoints/installations"

import {
  computePhotovoltaicInstallationCost,
  computePhotovoltaicVAT,
} from "@ensol/shared/entities/installations/costs/photovoltaic"
import { computePhotovoltaicInstallationCapacity } from "@ensol/shared/entities/installations/energy"
import { roundDecimalNumber } from "@ensol/shared/utils/format"

export const PhotovoltaicSubsidyType = {
  AUTO_CONSUMPTION: "AUTO_CONSUMPTION",
  GREEN_DEAL: "GREEN_DEAL",
} as const

export type PhotovoltaicSubsidyType = keyof typeof PhotovoltaicSubsidyType

type PhotovoltaicSubsidyAmountInput = {
  photovoltaicInstallation: InstallationsResponses.PhotovoltaicInstallation
  house: Pick<
    HousesResponses.House,
    "constructionYear" | "currentType" | "roofType" | "hasFlatRoof"
  >
  totalDiscount: number
}

type PhotovoltaicSubsidyEligibilityInput = {
  postcode: string
  hasGroundInstallation: boolean
}

const isAutoConsumptionEligible = ({
  hasGroundInstallation,
}: PhotovoltaicSubsidyEligibilityInput): boolean => !hasGroundInstallation

const computeAutoConsumptionSubsidy = ({
  photovoltaicInstallation,
}: PhotovoltaicSubsidyAmountInput): number => {
  const installedPowerCapacity = computePhotovoltaicInstallationCapacity(
    photovoltaicInstallation,
  )
  if (installedPowerCapacity <= 3)
    return roundDecimalNumber(installedPowerCapacity * 260)
  if (installedPowerCapacity <= 9)
    return roundDecimalNumber(installedPowerCapacity * 190)
  if (installedPowerCapacity <= 36)
    return roundDecimalNumber(installedPowerCapacity * 190)
  if (installedPowerCapacity <= 100)
    return roundDecimalNumber(installedPowerCapacity * 100)
  return 0
}

const isGreenDealEligible = ({
  postcode,
}: PhotovoltaicSubsidyEligibilityInput): boolean => postcode.startsWith("06")

const computeGreenDealSubsidy = ({
  photovoltaicInstallation,
  house,
  totalDiscount,
}: PhotovoltaicSubsidyAmountInput): number => {
  const installationCost = computePhotovoltaicInstallationCost({
    photovoltaicInstallation,
    house,
  })

  const installationCostWithDiscountBeforeTax =
    (installationCost - totalDiscount) /
    (1 +
      computePhotovoltaicVAT({
        photovoltaicInstallation,
        house,
      }))

  return Math.min(
    10000,
    roundDecimalNumber(installationCostWithDiscountBeforeTax / 2),
  )
}

type PhotovoltaicSubsidyInfo = {
  name: string
  shortName: string
  payoutConditions: string
  disclaimer?: string
  isEligible: (input: PhotovoltaicSubsidyEligibilityInput) => boolean
  computeAmount: (input: PhotovoltaicSubsidyAmountInput) => number
  adminFeeDescription: string
  disabled?: boolean
}

export const PHOTOVOLTAIC_SUBSIDIES: Record<
  PhotovoltaicSubsidyType,
  PhotovoltaicSubsidyInfo
> = {
  AUTO_CONSUMPTION: {
    name: "prime à l'autoconsommation",
    shortName: "Prime Autoconsommation",
    payoutConditions: "versée 1 an après l'installation",
    disclaimer:
      "Le montant de la prime est calculé chaque trimestre par la CRE (Commission de Régulation de l'énergie)",
    isEligible: isAutoConsumptionEligible,
    computeAmount: computeAutoConsumptionSubsidy,
    adminFeeDescription: "Contrat d'obligation d'achat pour revente de surplus",
  },
  GREEN_DEAL: {
    name: "prime Green Deal",
    shortName: "Green Deal",
    payoutConditions: "versée 9 mois après l'installation",
    isEligible: isGreenDealEligible,
    computeAmount: computeGreenDealSubsidy,
    adminFeeDescription: "Prise en charge des démarches Green Deal",
    disabled: true,
  },
}

export type PhotovoltaicSubsidy = {
  subsidyType: PhotovoltaicSubsidyType
  subsidyAmount: number
}

export const computePhotovoltaicSubsidyType = (
  hasGroundInstallation: boolean,
): PhotovoltaicSubsidyType | null => {
  if (hasGroundInstallation) {
    return null
  }

  return PhotovoltaicSubsidyType.AUTO_CONSUMPTION
}
