import { Alert, Box, Button, Group, Select, Stack } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import {
  IconAlertTriangle,
  IconDeviceFloppy,
  IconPigMoney,
} from "@tabler/icons-react"
import { useMemo } from "react"
import { Helmet } from "react-helmet-async"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import {
  SalesUpdateInstallationInput,
  salesUpdateInstallationSchema,
} from "@ensol/types/forms/installations"
import { InstallationUpdateType } from "@ensol/types/installation"
import {
  ConsumptionDataSource,
  ProductionDataSource,
} from "@ensol/types/simulation"

import { getCoords } from "@ensol/shared/entities/houses/coords"
import {
  REFERRAL_DISCOUNT,
  computeTotalDiscount,
} from "@ensol/shared/entities/installations/savings"
import { getFullName } from "@ensol/shared/entities/prospects"

import { queryClient } from "@ensol/entool/backend/queryClient"
import { Section } from "@ensol/entool/components/Section"
import { Field } from "@ensol/entool/components/form/Field"
import { HouseSection } from "@ensol/entool/components/form/House/HouseSection"
import { BatteryInstallation } from "@ensol/entool/components/form/Installation/BatteryInstallation"
import { EvChargerInstallation } from "@ensol/entool/components/form/Installation/EvChargerInstallation"
import { PhotovoltaicInstallation } from "@ensol/entool/components/form/Installation/PhotovoltaicInstallation"
import { NumberInput } from "@ensol/entool/components/form/NumberInput"
import { ContentContainer } from "@ensol/entool/components/layout/ContentContainer"
import { useInstallationEnergyStats } from "@ensol/entool/queries/energy"
import { useListProspectsQuery } from "@ensol/entool/queries/prospects"
import { NAVBAR_HEIGHT_IN_PX } from "@ensol/entool/styles/constants"
import { getSubformActions } from "@ensol/entool/utils/form/subforms"
import { isFormValid } from "@ensol/entool/utils/form/validation"

import { INSTALLATION_HEADER_HEIGHT_IN_PX, InstallationHeader } from "./Header"
import { Quote } from "./Quote"
import { SimulationParameters } from "./SimulationParameters"
import { Summary } from "./Summary"
import { TownHallRequirements } from "./TownHallRequirements"

type Props = {
  installationId?: string
  installationDate: Date
  name: string
  slug?: string
  productionDataSource: ProductionDataSource
  consumptionDataSource: ConsumptionDataSource
  yearlyConsumption?: number
  monthlyBill?: number
  autoConsumptionPercent?: number
  horizonProfileFilePath: string | null
  photovoltaicInstallation: InstallationsResponses.PhotovoltaicInstallation | null
  batteryInstallation: InstallationsResponses.BatteryInstallation | null
  evChargerInstallation: InstallationsResponses.EvChargerInstallation | null
  panelsLayout?: InstallationsResponses.Installation["panelsLayout"]
  referrerId?: string | null
  discount?: number | null
  prospect: InstallationsResponses.Installation["prospect"]
  house: HousesResponses.House<{
    include: {
      switchgridConsent: true
      switchgridOrder: true
      roofSections: true
    }
  }>
  project?: InstallationsResponses.Installation["project"]
  onSave: (
    installation: SalesUpdateInstallationInput,
  ) => Promise<InstallationsResponses.Installation>
  isSaving: boolean
}

export const Installation = ({
  installationId,
  installationDate,
  name,
  slug,
  productionDataSource,
  consumptionDataSource,
  monthlyBill,
  yearlyConsumption,
  autoConsumptionPercent,
  horizonProfileFilePath,
  photovoltaicInstallation,
  batteryInstallation,
  evChargerInstallation,
  panelsLayout,
  discount,
  referrerId,
  prospect,
  house,
  project,
  onSave,
  isSaving,
}: Props) => {
  const isNewInstallation = installationId === undefined

  const form = useForm<SalesUpdateInstallationInput>({
    validate: zodResolver(salesUpdateInstallationSchema),
    initialValues: {
      updateType: InstallationUpdateType.SALES,
      name,
      productionDataSource,
      consumptionDataSource,
      horizonProfileFilePath,
      monthlyBill: monthlyBill ?? 0,
      yearlyConsumption: yearlyConsumption ?? 0,
      autoConsumptionPercent: autoConsumptionPercent ?? 0,
      photovoltaicInstallation,
      batteryInstallation,
      evChargerInstallation,
      discount,
      referrerId,
      houseId: house.id,
      prospectId: prospect.id,
    },
  })

  const totalDiscount = useMemo(
    () => computeTotalDiscount(form.values.discount),
    [form.values.discount],
  )

  const {
    data: referrersOptions,
    isPending: isLoadingReferrers,
    isError: isErrorReferrers,
  } = useListProspectsQuery({
    select: (data) =>
      data.map((prospect) => ({
        label: getFullName(prospect),
        value: prospect.id,
      })),
  })

  const { data: energyStats, isPending: isEnergyStatsLoading } =
    useInstallationEnergyStats({
      coords: getCoords(house),
      installation: form.values,
      switchgridOrderId: house.switchgridOrder?.orderId ?? null,
    })

  const isInstallationValid = isFormValid(form)

  return (
    <Stack flex="1" gap="0" h="100%">
      <Helmet>
        <title>
          {getFullName(prospect)} - {name} | Entool
        </title>
      </Helmet>
      <InstallationHeader
        slug={slug}
        name={form.values.name}
        onNameChange={form.getInputProps("name").onChange}
        prospect={prospect}
        project={project}
      />
      <ContentContainer>
        <Group w="100%" align="flex-start" pos="sticky">
          <Stack w="calc(100% - 416px)">
            <Stack gap="xl">
              <HouseSection
                house={house}
                panelsLayout={panelsLayout}
                onSuccess={async () => {
                  installationId &&
                    (await queryClient.invalidateQueries({
                      queryKey: ["installations", installationId],
                    }))
                }}
              />
              <SimulationParameters
                form={form}
                installationValues={form.values}
                energyStats={energyStats}
                house={house}
                isNewInstallation={isNewInstallation}
                slug={slug}
              />
              <PhotovoltaicInstallation
                photovoltaicInstallation={form.values.photovoltaicInstallation}
                house={house}
                isNewInstallation={isNewInstallation}
                productionDataSource={form.values.productionDataSource}
                estimatedProduction={energyStats?.yearlyProduction}
                yearlyConsumption={form.values.yearlyConsumption}
                maxPanelsCount={energyStats?.maxPanelsCount}
                totalDiscount={totalDiscount}
                {...getSubformActions(form, "photovoltaicInstallation")}
              />
              <BatteryInstallation
                batteryInstallation={form.values.batteryInstallation}
                inverterType={
                  form.values.photovoltaicInstallation?.inverterType
                }
                {...getSubformActions(form, "batteryInstallation")}
              />
              <EvChargerInstallation
                evChargerInstallation={form.values.evChargerInstallation}
                {...getSubformActions(form, "evChargerInstallation")}
              />
              <Section title="Réductions" icon={IconPigMoney} isCollapsible>
                <Field name="Parrain">
                  <Select
                    w="100%"
                    clearable
                    searchable
                    data={referrersOptions}
                    disabled={isErrorReferrers || isLoadingReferrers}
                    {...form.getInputProps("referrerId")}
                    onChange={(value) => {
                      form.setFieldValue("referrerId", value)

                      if (!form.values.discount && value !== null) {
                        form.setFieldValue("discount", REFERRAL_DISCOUNT)
                      } else if (
                        value === null &&
                        form.values.discount === REFERRAL_DISCOUNT
                      ) {
                        form.setFieldValue("discount", 0)
                      }
                    }}
                  />
                </Field>
                <Field name="Prime voisin manuelle TTC" noBorder>
                  <NumberInput
                    w={200}
                    ml="auto"
                    suffix="€"
                    min={0}
                    {...form.getInputProps("discount")}
                  />
                </Field>
              </Section>
            </Stack>
          </Stack>
          <Box w={400}>
            <Stack
              pos="fixed"
              pt={16}
              top={INSTALLATION_HEADER_HEIGHT_IN_PX + NAVBAR_HEIGHT_IN_PX}
              bottom={32}
              style={{ overflowY: "scroll" }}
            >
              <Stack>
                <Button
                  loading={isSaving}
                  onClick={() => onSave(form.values)}
                  leftSection={<IconDeviceFloppy />}
                  disabled={!isInstallationValid}
                  data-test="submitButton"
                >
                  Enregistrer
                </Button>
                {!isInstallationValid && !isNewInstallation && (
                  <Alert
                    variant="light"
                    color="orange"
                    w={400}
                    title="L'installation est invalide"
                    icon={<IconAlertTriangle size={30} />}
                  >
                    Vérifier que les paramètres de simulation, le matériel et
                    les aides sélectionnées sont disponibles
                  </Alert>
                )}
              </Stack>
              <TownHallRequirements townHallId={house.townHallId} />
              {installationId !== undefined && !isNewInstallation && (
                <Quote
                  installationId={installationId}
                  installationDate={installationDate}
                  quoteFileName={`Devis Ensol - ${prospect.lastName} - ${name}.pdf`}
                  isInstallationValid={isInstallationValid}
                />
              )}
              <Summary
                installationDate={installationDate}
                installationValues={form.values}
                energyStats={energyStats}
                isEnergyStatsLoading={isEnergyStatsLoading}
                house={house}
                isInstallationValid={isInstallationValid}
                isNewInstallation={isNewInstallation}
              />
            </Stack>
          </Box>
        </Group>
      </ContentContainer>
    </Stack>
  )
}
